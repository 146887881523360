import type { NextPage } from 'next';
import Head from 'next/head';
import { Error as Page } from '@theark/react-common';
const ErrorPage: NextPage = () => {
	return (
		<>
			<Head>
				<title>Error</title>
				<meta name="viewport" content="initial-scale=1.0, width=device-width" />
			</Head>
			<Page statusCode={404} />
		</>
	);
};

export default ErrorPage;
